<template>
  <div style="position: relative">
    <v-container fluid class="pa-0 mx-2">
      <v-row>
        <v-col class="main-title fw-600 font-15"> Analytics </v-col>
      </v-row>
    </v-container>
    <highcharts :options="chartOptions" class="mr-4"></highcharts>
    <v-overlay absolute :value="loading">
      <span class="primary--text">loading....</span>
    </v-overlay>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import { showErrorMessage } from "@/utils/showError";
export default {
  props: {
    type: String,
    start_date: String,
    end_date: String,
  },
  data() {
    return {
      loading: false,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
          backgroundColor: "transparent",
          spacingTop: 27,
        },
        title: false,
        xAxis: {
          categories: [],
          labels: {
            style: {
              color: "#777F8F",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "24px",
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
            enabled: true,
          },
          gridLineDashStyle: "dash",
          labels: {
            style: {
              color: "#777F8F",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "39px",
            },
          },
        },
        legend: {
          align: "center",
          backgroundColor: "transparent",
          borderRadius: "8px",
          style: {
            backgroundColor: "red",
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: "#FFFFFF",
          borderRadius: 5,
          borderWidth: 0,
          distance: 10,
          headerFormat: "",
          pointFormat:
            '<div class="verfication-title">Verifications Done: <strong>{point.stackTotal}</strong></div><div class="series-title text-capitalize mt-1">{series.name}: <strong>{point.y}</strong></div>',
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            pointWidth: 48,
            borderWidth: 0,
          },
        },
        series: [],
      },
    };
  },
  methods: {
    /**
     * Loads graph statistics based on the specified type.
     * Sets loading to true to indicate data loading.
     * Constructs the data object including the type and, conditionally, start_date and end_date
     * if the type is 'custom_range'. Sends a POST request to fetch graph data.
     * Upon success, updates the chartOptions with the fetched data and sets loading to false.
     * Handles error responses by setting loading to false, showing error messages, and performing
     * actions based on specific error statuses, such as logging out and redirecting to the home page.
     *
     * @method loadGraphStats
     * @param {String} val - The type of graph statistics to load.
     */
    loadGraphStats(val) {
      this.loading = true;
      const data = {
        type: val,
        // Conditionally include start_date and end_date only if type is 'custom_range'
        ...(val === "custom_range" && {
          start_date: this.start_date,
          end_date: this.end_date,
        }),
      };

      ApiService.PostRequest("merchant/verification-request-graph", data)
        .then((res) => {
          const data = res.data.result.data;
          this.chartOptions.series = data.series;
          this.chartOptions.xAxis.categories = data.categories;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          showErrorMessage(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * Sets initial data when the component is first loaded.
     * If transaction_type is present in the route query, sets the type to that value.
     *
     * @method setDataOnFirstLoad
     */
    setDataOnFirstLoad() {
      if (this.$route.query.transaction_type)
        this.type = this.$route.query.transaction_type;
    },
  },
  watch: {
    type(val) {
      if (val) {
        this.loadGraphStats(val);
      }
    },
    start_date() {
      this.loadGraphStats("custom_range");
    },
  },
  beforeMount() {
    this.setDataOnFirstLoad();
  },
  mounted() {
    this.loadGraphStats(this.type);
  },
};
</script>

<style scoped>
.main-title {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  line-height: 18px;
}

.text-secandary {
  color: #909295;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  line-height: 18px;
}
.stats {
  background: white;
  width: 280px;
}
.card-border {
  height: 122px;
  border-left: 1px solid #e1ddcb;
}
.divider {
  width: 70px;
  border: dotted 1px solid #e3e3e3;
}

/* .stack-chart {
  padding: 25px 0px 25px 0px;
} */
.verfication-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: --v-greyColor-base;
}
.series-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: --v-greyColor-base;
}
</style>
