<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col class="text-card-title fw-600 font-15 mx-2"> Statistics </v-col>
        <v-col class="text-end mx-4">
          <CustomDateFilter
            @typeChanged="typeChanged"
            :type="type"
            @clearSearch="dateCleared"
            @dateChange="dateChanged"
          >
            <span
              class="select-date font-12 fw-400 text-capitalize"
              :class="name != null ? 'black--text' : ''"
            >
              {{ name === null ? "Select Date Range" : name }}
            </span>
            <v-icon
              class="date-range-icon ml-4"
              :color="name != null ? 'grey' : ''"
            >
              mdi-chevron-down
            </v-icon>
          </CustomDateFilter>
        </v-col>
      </v-row>
    </v-container>
    <stats :type="type" :start_date="start_date" :end_date="end_date" />
    <charts :type="type" :start_date="start_date" :end_date="end_date" />
  </div>
</template>

<script>
import stats from "./stats/Stats.vue";
import charts from "./charts/Charts.vue";
import CustomDateFilter from "../common/filters/CustomDateFilter.vue";
export default {
  name: "Dashboard",
  data() {
    return {
      type: "all_time",
      name: "All time",
      start_date: "",
      end_date: "",
    };
  },
  components: {
    stats,
    charts,
    CustomDateFilter,
  },
  methods: {
    /**
     * Handles the event when the date range is changed.
     * Sets the type to 'custom_range'. Determines the start_date and end_date based on the changedDate array.
     * Updates the name based on the start_date and end_date.
     *
     * @method dateChanged
     * @param {Array} changedDate - The array containing the changed date range.
     */
    dateChanged(changedDate) {
      this.type = "custom_range";
      if (changedDate[0] <= changedDate[1]) {
        this.start_date = changedDate[0];
        this.end_date = changedDate[1];
      } else if (changedDate[0] >= changedDate[1]) {
        this.start_date = changedDate[1];
        this.end_date = changedDate[0];
      } else if (changedDate[1] == undefined) {
        this.start_date = changedDate[0];
        this.end_date = "";
      } else {
        (this.start_date = ""), (this.end_date = "");
      }
      this.name = this.start_date + " " + this.end_date;
    },

    /**
     * Handles the event when the type is changed.
     * Sets the type and name based on the provided type value.
     *
     * @method typeChanged
     * @param {Object} type - The selected type object containing value and name.
     */
    typeChanged(type) {
      this.type = type.value;
      this.name = type.name;
    },

    /**
     * Resets the date range to 'all_time' and updates the name accordingly.
     *
     * @method dateCleared
     */
    dateCleared() {
      this.type = "all_time";
      this.name = "All Time";
    },
  },
};
</script>

<style scoped>
.date-range-icon {
  color: #909295;
  font-size: 14px !important;
  margin-right: 4px;
}
</style>
