<template>
  <div color="appBackGround" flat class="ma-5">
    <StackedCharts :type="type" :start_date="start_date" :end_date="end_date"/>

  </div>
</template>

<script>
import StackedCharts from "./partials/StackedCharts.vue";

import {mapGetters} from "vuex";

export default {
  components: {
    StackedCharts,
  },
  computed: {
    ...mapGetters(["userEmailGetter"]),
  },
  props: {
    type: String,
    start_date: String,
    end_date: String,
  },
};
</script>

<style scoped>
.main-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #292b30;
  display: block;
}
</style>
