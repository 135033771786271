<template>
  <div color="white" flat class="pa-2">
    <div class="stats-tab">
      <div
        v-for="(group, type) in getSortedResponse"
        :key="type"
        class="stats-div"
      >
        <v-card
          class="stats elevation-0"
          @click="handleDashboardClick(type, 1)"
        >
          <v-img src="@/assets/icons/card-bg-img.svg" class="stats">
            <div v-if="type === 'liveness'">
              <img class="image ml-3 mt-4" src="@/assets/icons/live.svg" />
            </div>
            <div v-else-if="type === 'face_search'">
              <img
                class="image ml-3 mt-4"
                src="@/assets/icons/face-search1-n-dashboard.svg"
              />
            </div>
            <div v-else-if="type === 'age_estimation'">
              <img
                class="image ml-3 mt-4"
                src="@/assets/icons/age-verification-card-dashboard.svg"
              />
            </div>
            <div v-else-if="type === 'photo_id_match'">
              <img class="image ml-3 mt-4" src="@/assets/icons/face-1-1.svg" />
            </div>
            <div v-else-if="type === 'document_ocr'">
              <img class="image ml-3 mt-4" src="@/assets/icons/live.svg" />
            </div>

            <div class="mt-4 ml-3" v-if="loader">
              <v-progress-circular
                indeterminate
                :color="group.loaderColor"
              ></v-progress-circular>
            </div>

            <div v-if="!loader">
              <div v-if="type === 'liveness'">
                <p class="ml-3 mt-1 font-12 fw-500 text-card-title">
                  Liveness Detection
                </p>
              </div>
              <div v-else-if="type === 'face_search'">
                <p class="ml-3 mt-1 font-12 fw-500 text-card-title">
                  Face Search
                </p>
              </div>
              <div v-else-if="type === 'age_estimation'">
                <p class="ml-3 mt-1 font-12 fw-500 text-card-title">
                  Age Estimation
                </p>
              </div>
              <div v-else-if="type === 'photo_id_match'">
                <p class="ml-3 mt-1 font-12 fw-500 text-card-title">
                  Face Match
                </p>
              </div>
              <div v-else-if="type === 'document_ocr'">
                <p class="ml-3 mt-1 font-12 fw-500 text-card-title">
                  Document OCR
                </p>
              </div>

              <div v-if="type === 'liveness'">
                <p class="ml-3 mt-1 font-20 fw-600 text-card-title">
                  {{ calculateSum(group.ACCEPTED, group.DECLINED) }}
                </p>
              </div>
              <div v-else-if="type === 'face_search'">
                <p class="ml-3 mt-1 font-20 fw-600 text-card-title">
                  {{ calculateSum(group.ACCEPTED, group.NOT_FOUND) }}
                </p>
              </div>
              <div v-else-if="type === 'age_estimation'">
                <p class="ml-3 mt-1 font-20 fw-600 text-card-title">
                  {{
                    calculateSum(
                      group.ACCEPTED,
                      group.DECLINED,
                      group.NOT_FOUND
                    )
                  }}
                </p>
              </div>
              <div v-else-if="type === 'photo_id_match'">
                <p class="ml-3 mt-1 font-20 fw-600 text-card-title">
                  {{
                    calculateSum(
                      group.ACCEPTED,
                      group.DECLINED,
                      group.NOT_FOUND
                    )
                  }}
                </p>
              </div>
              <div v-else-if="type === 'document_ocr'">
                <p class="ml-3 mt-1 font-20 fw-600 text-card-title">
                  {{ calculateSum(group.ACCEPTED, group.TIMEOUT) }}
                </p>
              </div>

              <v-divider class="divider ml-3 mt-3"></v-divider>
              <div v-if="type === 'liveness'">
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Passed Liveness:
                  <strong class="text-card-title fw-600">{{
                    group.ACCEPTED != null ? group.ACCEPTED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Failed Liveness:
                  <strong class="text-card-title fw-600">{{
                    group.DECLINED != null ? group.DECLINED : "0"
                  }}</strong>
                </p>
              </div>

              <div v-else-if="type === 'face_search'">
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Found:
                  <strong class="text-card-title fw-600">{{
                    group.ACCEPTED != null ? group.ACCEPTED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Not Found:
                  <strong class="text-card-title fw-600">{{
                    group.NOT_FOUND != null ? group.NOT_FOUND : "0"
                  }}</strong>
                </p>
              </div>

              <div v-else-if="type === 'age_estimation'">
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Passed Verifications:
                  <strong class="text-card-title fw-600">{{
                    group.ACCEPTED != null ? group.ACCEPTED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Failed Verifications:
                  <strong class="text-card-title fw-600">{{
                    group.DECLINED != null ? group.DECLINED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Face Not Found:
                  <strong class="text-card-title fw-600">{{
                    group.NOT_FOUND != null ? group.NOT_FOUND : "0"
                  }}</strong>
                </p>
              </div>

              <div v-else-if="type === 'photo_id_match'">
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Matched:
                  <strong class="text-card-title fw-600">{{
                    group.ACCEPTED != null ? group.ACCEPTED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Not Matched:
                  <strong class="text-card-title fw-600">{{
                    group.DECLINED != null ? group.DECLINED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Face Not Found:
                  <strong class="text-card-title fw-600">{{
                    group.NOT_FOUND != null ? group.NOT_FOUND : "0"
                  }}</strong>
                </p>
              </div>

              <div v-else-if="type === 'document_ocr'">
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Data Extracted:
                  <strong class="text-card-title fw-600">{{
                    group.ACCEPTED != null ? group.ACCEPTED : "0"
                  }}</strong>
                </p>
                <p class="ml-3 mt-2 text-card-title font-12 fw-400">
                  Data Not Extracted:
                  <strong class="text-card-title fw-600">{{
                    group.TIMEOUT != null ? group.TIMEOUT : "0"
                  }}</strong>
                </p>
              </div>
            </div>
          </v-img>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { showErrorMessage } from "../../../utils/showError";
export default {
  data() {
    return {
      loader: false,
      transformedResponse: {},
      dummydata: [
        { status: "FOUND", type: "face_search", count: 0 },
        { status: "NOT_FOUND", type: "photo_id_match", count: 0 },
        { status: "NOT_FOUND", type: "face_search", count: 0 },
        { status: "TIMEOUT", type: "face_search", count: 0 },
        { status: "DECLINED", type: "liveness", count: 0 },
        { status: "ACCEPTED", type: "age_estimation", count: 0 },
        { status: "ACCEPTED", type: "photo_id_match", count: 0 },
        { status: "INITIATED", type: "liveness", count: 0 },
        { status: "NOT_FOUND", type: "age_estimation", count: 0 },
        { status: "DECLINED", type: "photo_id_match", count: 0 },
        { status: "DECLINED", type: "age_estimation", count: 0 },
        { status: "ACCEPTED", type: "face_search", count: 0 },
        { status: "PENDING", type: "liveness", count: 0 },
        { status: "ACCEPTED", type: "liveness", count: 0 },
        { status: "TIMEOUT", type: "document_ocr", count: 0 },
        { status: "DECLINED", type: "document_ocr", count: 0 },
        { status: "ACCEPTED", type: "document_ocr", count: 0 },
      ],
    };
  },
  props: {
    type: String, // type prop
    start_date: String, // start_date prop for custom dates
    end_date: String, // end_date prop for custom dates
  },

  mounted() {
    this.dashboardStats(this.type);
    this.transformData();
  },
  computed: {
    ...mapGetters(["getStats"]),

    /**
     * Sorts the response data keys based on a predefined sortOrder array.
     * Creates a new object with keys sorted according to the sortOrder.
     * If a key is not found in the sortOrder, it is moved to the end of the sorted keys.
     *
     * @method getSortedResponse
     * @returns {Object} The response data object with keys sorted based on the sortOrder.
     */
    getSortedResponse() {
      const sortOrder = [
        "liveness",
        "photo_id_match",
        "face_search",
        "age_estimation",
        "document_ocr",
      ];
      const sortedKeys = Object.keys(this.transformedResponse).sort((a, b) => {
        const aIndex = sortOrder.indexOf(a);
        const bIndex = sortOrder.indexOf(b);

        if (aIndex === -1) return 1; // If a key is not in sortOrder, move it to the end
        if (bIndex === -1) return -1; // If b key is not in sortOrder, move it to the end

        return aIndex - bIndex;
      });

      // Create a new object with sorted keys
      const sortedData = {};
      sortedKeys.forEach((key) => {
        sortedData[key] = this.transformedResponse[key];
      });
      return sortedData;
    },
  },

  watch: {
    getStats: {
      handler(newStats) {
        this.transformData(newStats);
      },
      immediate: true,
    },
    type(val) {
      if (val) {
        this.dashboardStats(val);
      }
    },
    start_date() {
      this.dashboardStats("custom_range");
    },
  },

  methods: {
    /**
     * Calculates the sum of the provided properties.
     *
     * @method calculateSum
     * @param {...(Number|null)} properties - The properties to sum.
     * @returns {Number} The sum of the provided properties.
     */
    calculateSum(...properties) {
      return properties.reduce(
        (acc, value) => acc + (value == null ? 0 : value),
        0
      );
    },

    /**
     * Sorts the keys of an object based on a custom sorting order.
     * Transforms keys if necessary and sorts them in descending order.
     *
     * @method sortObjectKeys
     * @param {Object} obj - The object whose keys are to be sorted.
     * @returns {Object} The object with keys sorted in descending order.
     */
    sortObjectKeys(obj) {
      return Object.keys(obj)
        .sort((a, b) => {
          const transformedA = a === "photo_id_match" ? "face_search1_1" : a;
          const transformedB = b === "photo_id_match" ? "face_search1_1" : b;

          return transformedB.localeCompare(transformedA);
        })
        .reduce((sortedObj, key) => {
          sortedObj[key] = obj[key];
          return sortedObj;
        }, {});
    },

    /**
     * Transforms data into the desired format and updates the transformedResponse object.
     * If getStats is available and contains data, it iterates over each transaction to construct
     * the transformedResponse object. Otherwise, it uses dummydata to construct the object.
     *
     * @method transformData
     */
    transformData() {
      this.transformedResponse = {};

      if (this.getStats && this.getStats.length > 0) {
        this.getStats.forEach((transaction) => {
          const type = transaction.type;
          const status = transaction.status;
          const count = transaction.count;

          if (!this.transformedResponse[type]) {
            this.$set(this.transformedResponse, type, {});
          }

          this.$set(this.transformedResponse[type], status, count);
        });
      } else {
        this.dummydata.forEach((transaction) => {
          const type = transaction.type;
          const status = transaction.status;
          const count = transaction.count;

          if (!this.transformedResponse[type]) {
            this.$set(this.transformedResponse, type, {});
          }

          this.$set(this.transformedResponse[type], status, count);
        });
      }
    },

    /**
     * Fetches dashboard statistics based on the specified type.
     * Constructs the data object including the type and, conditionally, start_date and end_date
     * if the type is 'custom_range'. Dispatches a Vuex action to fetch statistics.
     * Upon success, transforms the data and updates the loader state. Handles errors by
     * setting loader to false and showing error messages.
     *
     * @method dashboardStats
     * @param {String} val - The type of dashboard statistics to fetch.
     */
    async dashboardStats(val) {
      const data = {
        type: val,
        // Conditionally include start_date and end_date only if type is 'custom_range'
        ...(val === "custom_range" && {
          start_date: this.start_date,
          end_date: this.end_date,
        }),
      };
      this.loader = true;
      try {
        await this.$store.dispatch("GET_STATS", data);
        this.transformData();
      } catch (error) {
        this.loader = false;
        showErrorMessage(error);
      } finally {
        this.loader = false;
      }
    },

    /**
     * Handles the event when the date range is changed.
     * Constructs the data object including the type and, if available, start_date and end_date.
     * Sets the name based on start_date and end_date. Calls dashboardStats to fetch statistics
     * if start_date or end_date is provided.
     *
     * @method dateChanged
     * @param {String} type - The type of date range change.
     */
    dateChanged(type) {
      const data = { type };
      if (this.start_date !== "") {
        data.start_date = this.start_date;
      }
      if (this.end_date !== "") {
        data.end_date = this.end_date;
      }
      this.name = data.start_date + " " + data.end_date;
      if (data.start_date || data.end_date) this.dashboardStats(data);
    },

    /**
     * Handles the event when the type is changed.
     * Sets the type and name based on the provided type value. Calls dashboardStats to fetch
     * statistics with the updated type.
     *
     * @method typeChanged
     * @param {Object} type - The selected type object containing value and name.
     */
    typeChanged(type) {
      this.type = type.value;
      this.name = type.name;
      this.dashboardStats({ type: this.type });
    },

    /**
     * Resets the date range to 'all_time' and updates the name accordingly.
     * Calls dashboardStats to fetch statistics for the 'all_time' range.
     *
     * @method dateCleared
     */
    dateCleared() {
      this.type = "all_time";
      this.name = "All Time";
      this.dashboardStats({ type: this.type });
    },

    /**
     * Handles the click event on the dashboard.
     * Redirects to the transactions page with selectedTitle, type, start_date, and end_date in the query,
     * if the count is greater than 0.
     *
     * @method handleDashboardClick
     * @param {String} title - The title of the clicked dashboard item.
     * @param {Number} count - The count associated with the clicked item.
     */
    handleDashboardClick(title, count) {
      if (count > 0) {
        this.$router.push({
          path: "/transactions",
          query: {
            selectedTitle: title,
            type: this.type,
            start_date: this.start_date,
            end_date: this.end_date,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.text-card-title {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
}
.stats {
  background: #eef2f6;
  width: 100%;
  height: 194px;
  border-radius: 8px;
  transition: width 0.5s ease;
}

.date-range-icon {
  color: #909295;
  font-size: 14px !important;
  margin-right: 4px;
}
.image {
  width: 30px;
  height: 30px;
}

.divider {
  width: 70px;
  border: dotted 1px solid #e1ddcb;
}

p {
  margin-bottom: 0 !important;
}
.stats-tab {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.stats-div {
  flex: 25%;
  max-width: 20%;
  padding: 0 4px;
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 800px) {
  .stats-div {
    flex: 50%;
    max-width: 50%;
    padding: 4px 4px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .stats-div {
    flex: 100%;
    max-width: 100%;
    padding: 4px 0;
  }
}
</style>
